<template>
  <div class="uk-container">
    <v-row class="uk-container">
      <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
        <!-- <base-arrow-back-icon @click="$router.go(-1)">{{
          rightArrowIcon
        }}</base-arrow-back-icon> -->

        لوحة التحكم
      </h1>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" class="mt-15">
        <v-card elevation="7" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                الدورات
              </v-list-item-title>
              <v-list-item-subtitle>{{
                counters.total_courses
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" class="mt-15">
        <v-card elevation="7" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                الطلاب
              </v-list-item-title>
              <v-list-item-subtitle>{{
                counters.total_students
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" class="mt-15">
        <v-card elevation="7" outlined>
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                مدرج
              </v-list-item-title>
              <v-list-item-subtitle>{{
                counters.total_enrolls
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
        <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
          الدورات المرفوضة / التي تتطلب إعادة إرسال
        </h1>
    <v-col cols="12">
        <table-actions-nav
          type="course"
          v-model="search2"
          :data="{name: 'Denied / Resubmitted Courses', data: adminCourses}"
          :options="{ withAddNew: false, withSearch: true, withExport: true }"
        ></table-actions-nav>
      </v-col>
      <v-row class="uk-margin-small-top">
      <template>
        <v-data-table
          :headers="coursesHeaders"
          :items="adminCourses"
          :items-per-page="5"
          :search="search2"
          class="elevation-1 w-100"
          :footer-props="{
            itemsPerPageText: 'عدد الأسطر في الجدول:',
          }"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <table-actions
              :status="item.status"
              :type="item.type"
              @remove="remove(item.course_id)"
              @edit="edit(item.course_id)"
              @show="show(item.course_id)"
              @courses="goTo(item.course_id)"
              @offline="offline(item.course_id)"
              @resubmit="statusConfirm = item.course_id"
              course
            ></table-actions>
          </template>
        </v-data-table>
      </template>
    </v-row>
    <v-row class="uk-container uk-margin-large-top">
      <v-col cols="12">
        <h1 class="font-weight-bold" :style="{ color: this.fontWeightBold }">
          طلابي
        </h1>
      </v-col>
      <v-col cols="12">
        <table-actions-nav
          v-model="search"
          :data="export_things"
          :options="{ withAddNew: false, withSearch: true, withExport: true }"
        ></table-actions-nav>
      </v-col>
    </v-row>
    <v-row class="uk-margin-small-top uk-margin-large-bottom">
      <v-data-table
        :headers="studentsHeaders"
        :items="students"
        :search="search"
        :items-per-page="5"
        class="elevation-1 w-100"
        :footer-props="{
          itemsPerPageText: 'عدد الأسطر في الجدول:',
        }"
      >
      </v-data-table>
    </v-row>
    <confirm-status
      v-if="statusConfirm >= 0"
      v-bind="{ id: statusConfirm, isOpen: true }"
      @handleClose="statusConfirm = -1"
      @handelRequest="resubmit"
    ></confirm-status>
  </div>
</template>

<script>
import TableActionsNav from "../Components/TableActionsNav";
import TableActions from "../Components/TableActions";
import confirmStatus from './Course/Pages/confirmStatus'
import { mapActions, mapGetters } from "vuex";
// import BaseArrowBackIcon from "@/core/Base/Arrows/BaseArrowBackIcon";
export default {
  name: "InstructorDashBoard",
  methods: {
    ...mapActions(["fetchCountersInstructor", "fetchStudentsInstructor", "fetchCourses", "resubmitCourse"]),
    init() {
      this.fetchCourses({ page: 0, size: 100, q: "", lang: "ar" }).then((res) => {
        this.adminCourses = [];
        this.courses.forEach((course) => {
          let displayCourse = { ...course };
          displayCourse.status = this.translate(course.status);
          displayCourse.type = this.translate(course.type);
          if (course.status === "resubmit" || course.status === "denied") {
            this.adminCourses.push(displayCourse);
          console.log(course)
          }
        });
      });
    },
    resubmit(payload) {
      console.log('resubmit',payload.id);
      this.resubmitCourse(payload.id).then(() => {
        this.statusConfirm = -1;
        this.init();
      });
    },
    translate(word) {
      switch (word) {
        case "live":
          return "نشط";
        case "denied":
          return "مرفوض";
        case "resubmit":
          return "إعادة الإرسال";
        case "pending":
          return "قيد الانتظار";
        case "online":
          return "عبر الإنترنت";
        case "offline":
          return "حضورية";
        default:
          return word;
      }
    },
    remove(id) {
      console.log(id);
      // console.log(this.id);
      this.deleteCourseInstructor(id).then(() => {
        this.init();
      });
    },
    edit(id) {
      console.log(id);
      this.$router.push({ name: "updateCourse", params: { id: id } });
    },
    show(id) {
      console.log(id);
      this.$router.push({ name: "showCourse", params: { id: id } });
    },
    goTo(id) {
      console.log(id);
      this.$router.push({ name: "modulesIndex", params: { id: id } });
    },
    offline(id) {
      console.log(id);
      this.$router.push({ name: "addOffline", params: { id: id } });
    },
  },
  created() {
    this.init();
    this.fetchCountersInstructor();
    this.fetchStudentsInstructor();
  },
  computed: {
    ...mapGetters({
      counters: "getCountersInstructor",
      students: "getStudentsInstructor",
      courses: "getCourses",
    }),
    export_things() {
      return {
        name: "Students",
        fields: {
          " اسم الطالب": "student_name",
          "البريد الالكتروني ": "student_email",
          "الدورة ": "student_courses",
        },
        data: this.students,
      };
    },
  },
  components: {
    TableActionsNav, confirmStatus, TableActions
    // BaseArrowBackIcon,
  },
  data() {
    return {
      search: "",
      search2: "",
      statusConfirm: -1,
      adminCourses: [],
      coursesHeaders: [
        {
          text: "اسم الدورة",
          align: "center",
          sortable: false,
          value: "course_ar_title",
        },
        { text: "الحالة", value: "status", align: "center" },
        { text: "النوع", value: "type", align: "center" },
        { text: "الرسالة", value: "message", align: "center" },
        { text: "", value: "actions", align: "center" },
      ],
      studentsHeaders: [
        {
          text: "اسم الطالب",
          align: "center",
          sortable: false,
          value: "student_name",
        },
        { text: "البريد الالكتروني", value: "student_email", align: "center" },
        { text: "الدورة", value: "student_courses", align: "center" },
      ],
    };
  },
};
</script>

<style >
.v-card {
  border-radius: 20px;
}

.v-data-table {
  width: 100%;
}
.v-application--is-rtl .v-data-footer__pagination {
  direction: ltr !important;
}
.v-data-table-header tr th {
  text-align: center !important;
}
</style>
